import { inject } from "@angular/core";

import { CanActivateFn, Router } from "@angular/router";
import { AdminUrl } from "../../admin/admin.url";
import { AuthenticationService } from "../../shared/services/api/authentication.service";
import { PartnerService } from "../../shared/services/api/partner.service";

export function PartnerResolverGuard(): CanActivateFn {
  return async (route): Promise<boolean> => {
    const router = inject(Router);
    const partnerService = inject(PartnerService);

    const partnerId = parseInt(route.paramMap.get("partnerId")!, 10);
    const lastSelectedPartnerId = partnerService.lastSelectedPartnerId;

    const isAdminUser = inject(AuthenticationService).isRoleAdmin();
    const isPartnerIdCorrect = !isNaN(partnerId);
    const isNewPartnerSelected = partnerId !== lastSelectedPartnerId;
    const isCurrentPartnerNotLoaded = !partnerService.currentPartner$.value;

    if (!isPartnerIdCorrect) {
      await router.navigate(["/not-found"]);
      return false;
    }

    if (isNewPartnerSelected || isCurrentPartnerNotLoaded) {
      try {
        const partner = await partnerService.setCurrentPartnerId(partnerId);

        if (partner) {
          if (partner.isProspect && isAdminUser) {
            partnerService.clearCurrentPartner();
            await router.navigateByUrl(AdminUrl.Home());
            return false;
          } else {
            partnerService.setCurrentPartner(partner);
          }
        }
      } catch (err) {
        await router.navigate(["/welcome"]);
        return false;
      }
    }
    return true;
  };
}
